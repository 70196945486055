<script lang="ts" setup>
type GameItem = {
	type?: string;
	slug?: string;
	type_component?: string;
	row_count?: number;
};

const props = defineProps<{
	itemSection: number;
	hideOnLeave?: boolean;
}>();

const isGuest = useIsGuest();
const { select } = useGamesState();
const { homePageData, lastGames } = useHomePage();
const { isMobile } = useDevice();

const games = computed(() => homePageData.value?.schemeSections?.[props.itemSection] || []);

const isRecentlyPlayedSection = (game: GameItem) => game?.slug === "last" && !isGuest.value && lastGames.value?.length;

const isPrerenderedComponent = (count: number) => count <= 2;

const { t } = useT();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const gameSectionHeight = {
	slider_horizontal: 235,
	slider_vertical: isMobile ? 210 : 277
};

const skeletonHeight = ({ type, rows = 1 }: { type?: keyof typeof gameSectionHeight; rows?: number }) => {
	const headerHeight = 50; // default header height of section
	const defaultGridSize = isMobile ? 140 : 178;

	return `${(type ? gameSectionHeight[type] : defaultGridSize) * rows + headerHeight}px`;
};
</script>

<template>
	<div v-if="games.length" class="section-item">
		<template v-for="(game, index) in games" :key="index">
			<MHomeSectionWrapper
				v-if="game && isRecentlyPlayedSection(game)"
				title="homePage.gamesCategory.recentlyPlayed"
				icon="24/recent"
				data-tid="recent"
			>
				<MGameType
					v-for="(item, num) in lastGames"
					:key="num"
					:game="item"
					@toggle-favorite="handleToggleToFavoriteClick"
				/>
			</MHomeSectionWrapper>

			<OLazyComponentNew
				v-else-if="!!homePageData?.games[camelCase(game?.slug ?? '')]?.length"
				:id="`home-game-${game?.slug}`"
				:prerender="itemSection === 0 ? isPrerenderedComponent(Number(index)) : false"
				:hideOnLeave="hideOnLeave"
				:height="skeletonHeight({ type: game?.type_component, rows: !game?.row_count ? 1 : game?.row_count })"
			>
				<MHomeSectionWrapper
					:sectionName="game?.slug ?? ''"
					:row="game?.row_count ?? 1"
					:title="homePageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
					:icon="homePageData?.gamesInfo[camelCase(game?.slug ?? '')]?.logo || ''"
					showAll
					isAdminIcon
					@view-all-action="navigateTo(`/issues/${game?.slug}/`)"
				>
					<MGameType
						v-for="(item, num) in select(homePageData?.games[camelCase(game?.slug ?? '')])"
						:key="num"
						:type="game?.type_component"
						:game="item"
						@toggle-favorite="handleToggleToFavoriteClick"
					/>
				</MHomeSectionWrapper>
			</OLazyComponentNew>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.section-item {
	display: flex;
	flex-direction: column;
	gap: 42px;

	@include media-breakpoint-up(md) {
		gap: 36px;
	}
}
</style>
